<template>
  <div>
    <v-row
      class="mb-0 ankara"
      :style="
        $vuetify.breakpoint.xs ? 'padding:2rem 1rem 2rem' : 'padding:4rem 7%'
      "
    >
      <!-- <div class="overlay"></div> -->
      <v-col
        cols="12"
        sm="6"
        :class="$vuetify.breakpoint.xs ? 'pb-10' : ''"
        style="z-index:2"
      >
        <h1 class="black--text">
          Reliable Cold-Chain <br />
          Monitoring Device
        </h1>
        <h3 class="black--text mt-4" style="font-weight:500">
          Make informed decision and eliminate <br />
          losses with the Mote
        </h3>
        <v-btn
          rounded
          color="#28A84A"
          style="width:fit-content"
          class="mt-6 px-8 py-5"
          @click="goMote()"
        >
          <span style=" font-weight:900; font-size:12px" class="white--text">BUY NOW!</span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="right-col">
        <div class="d-shape">
          <div class="img">
            <img src="../../assets/SVG/demo/Group 2072 (1).png" alt="" />
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- apparatus equipment section -->
    <div class="py-12" style="background: rgba(242, 245, 247, 0.46);">
      <div class="top-apparatus text-center">
        <h2 style="font-size:28px" class="custom-header">
          Custom made for your Business
        </h2>
        <p class="py-6 mx-2">
          Available for Pharmacies, laboratories, poultries, diary and
          livestocks farms.
        </p>
      </div>

      <v-row class="px-12 py-5" style="justify-content:space-evenly">
        <div class="apparatus-card" v-for="(m, i) in icon" :key="i">
          <img
            :src="m.img"
            style="height: 4rem;max-width: 4rem;"
            alt=""
            class="mb-4"
          />
          <p class="p1 font-weight-bold">{{ m.p1 }}</p>
          <!-- <p class="p2">{{ m.p2 }}</p> -->
          <p class="text-center">
            {{ m.content }}
          </p>
        </div>
      </v-row>
    </div>

    <!-- Slider section -->
    <div :class="$vuetify.breakpoint.xs ? '' : ''" style="width:100%">
      <div style="position:relative">
        <!-- <img
          src="../../assets/background/Group 1934@3x.png"
          alt="partners"
          width="100%"
        /> -->
        <div
          class="pt-12"
          style="height:auto; position: relative;top: 0px;width: 100%;"
        >
          <div class="heading-title mb-5">
            <span class="stroke">
              <div class="line"></div>
              <div class="line2"></div>
            </span>
            <h3
              style="font-size:28px"
              class="text-center"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-6' : ''"
            >
              Join our community of happy users
            </h3>
          </div>

          <v-row
            style="flex-flow: row;overflow-x: auto;"
            :class="$vuetify.breakpoint.mdAndDown ? 'mx-4' : 'mx-16'"
            id="container__"
          >
            <span>
              <img
                :src="m.img"
                alt=""
                v-for="(m, i) in clientImg"
                :key="i"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 8rem;height: 8rem; object-fit:contain'
                    : 'width: 10rem;height: 10rem; object-fit:contain'
                "
                :class="i > 8 ? 'mr-12' : ''"
              />
            </span>
          </v-row>
        </div>
      </div>
    </div>

    <v-row
      :class="$vuetify.breakpoint.smAndDown ? 'px-6 py-8' : 'px-16 py-16'"
      justify="center"
    >
      <!-- <v-col cols="12" md="5">
        <h2 class="mb-16 mt-6">
          We’re looking forward to meeting you.
        </h2>
        <p class="mb-10">
          <span>Address:</span> Plot 2, Ikosi Road, Oregun, Lagos, Nigeria.
        </p>
        <p class="mb-10"><span>Phone Number:</span> (234)814-298-1261</p>
        <p class="mb-10"><span>Email:</span> info@gricd.com</p>
      </v-col> -->
      <v-col
        cols="12"
        md="8"
        :style="
          $vuetify.breakpoint.xs ? 'padding: 1rem 2rem' : 'padding: 1rem 7%;'
        "
      >
        <!-- <h4 class="mb-6">Leave a Reply</h4>
        <p class="reply mb-6">
          Your email address will not be published.
        </p> -->

        <v-form ref="form" v-model="valid">
          <div class="name">
            <v-text-field
              v-model="name"
              color="#28a84a"
              outlined
              label="Full Name"
              class="text-field"
              :rules="nameRules"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              outlined
              color="#28a84a"
              label="E-mail"
              :rules="emailRules"
              required
            ></v-text-field>
          </div>
          <div class="name">
            <v-text-field
              v-model="select"
              color="#28a84a"
              outlined
              label="Company Name"
              class="text-field"
              :rules="compRules"
              required
            ></v-text-field>

            <vue-tel-input-vuetify
              @input="onInput"
              defaultCountry='NGR'
              v-model="phone.number"
              outlined
              :error-messages="phoneError"
              color="#28a84a"
              placeholder="Phone Number"
              @keydown="isNumber($event)"
              :rules="phoneRules"
              required
              :style="$vuetify.breakpoint.xs ? 'width:100%' : 'width:47%'"
            />
          </div>

          <div class="name">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="dateFormatted"
                  label="Schedule Date"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  required
                  :rules="dateRules"
                  @blur="date = parseDate(dateFormatted)"
                  hint="DD/MM/YYYY"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                color="#28a84a"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>

            <!-- <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{on}">
                <v-text-field
                  v-model="time"
                  label="Schedule Time"
                  readonly
                  v-on="on"
                  outlined
                  required
                  :rules="timeRules"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="time"
                ampm-in-title
                elevation="15"
                full-width
                color="#28a84a"
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu> -->

            <div class="time">
              <vue-timepicker
                class="timepicker"
                format="hh:mm A"
                placeholder="Schedule a Time"
                v-model="time"
                @change="inputHandler"
                required
              ></vue-timepicker>
            </div>
          </div>

          <v-select
            v-model="industry"
            :items="items"
            color="#28a84a"
            outlined
            :rules="[(v) => !!v || 'Industry is required']"
            label="Industry"
            required
            item-color="green"
            item-text="green"
          >
          </v-select>

          <!-- <v-textarea
            v-model="comment"
            outlined
            color="#28a84a"
            name="input-7-4"
            label="Comment"
          ></v-textarea> -->

          <v-btn
            style="font-size:12px"
            @click="validate"
            color="#28a84a"
            class="white--text mt-6 py-6"
            :loading="loading"
            width="100%"
            >SCHEDULE A DEMO</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';
// import VueTimepicker from 'vue2-timepicker';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import 'vue2-timepicker/dist/VueTimepicker.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ic1 from '../../assets/SVG/demo/Group 1422.svg';
import ic2 from '../../assets/SVG/demo/Group 1419.svg';
import ic3 from '../../assets/SVG/demo/Group 1421.svg';
import cl1 from '../../assets/clients/image 25@3x.png';
import cl2 from '../../assets/clients/image 26@3x.png';
import cl3 from '../../assets/clients/image 42.png';
import cl4 from '../../assets/clients/image 28 (1).png';
import cl5 from '../../assets/clients/image 41.png';
import cl6 from '../../assets/clients/image 31 (1).png';
import cl7 from '../../assets/clients/Group 2023.png';
import cl8 from '../../assets/clients/image 38.png';
import cl9 from '../../assets/clients/Group 2022.png';
import cl10 from '../../assets/clients/image 25.png';
import cl11 from '../../assets/clients/image 30.png';
import cl12 from '../../assets/clients/image 34 (1).png';
import cl13 from '../../assets/clients/image 44.png';

Vue.use(VueToast, {
  position: 'top-right',
  duration: 3000,
  dismissible: true,
});

import axios from 'axios';
export default {
  components: {
    VueTelInputVuetify,
    VueTimepicker,
  },
  data() {
    return {
      time: {
        HH: '',
        MM: '',
        A: '',
      },
      displayTime: '',
      menu2: false,
      modal2: false,
      // date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),

      // date: null,
      // dateFormatted: null,
      menu: false,
      valid: false,
      phoneRules: [(v) => !!v || 'Phone is required'],
      myPhone: '',
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        //   (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: '',
      subject: '',
      subjectRules: [(v) => !!v || 'Subject is required'],
      dateRules: [(v) => !!v || 'Date is required'],
      dateRules2: [(v) => v < this.date || 'Date is required'],
      timeRules: [(v) => !!v || 'Time is required'],
      compRules: [(v) => !!v || 'Time is required'],
      comment: '',
      commentRules: [(v) => !!v || 'Comment is required'],
      info: '',
      phoneError: '',
      loading: false,
      items: ['Agriculture', 'Logistics', 'Health', 'Other sector'],
      industry: null,
      icon: [
        {
          img: ic1,
          p1: 'Monitor your Products',
          p2: 'Plasma Sample',
          content:
            'Get real time data on the location, temperature and humidity of your products in storage and in transit',
        },
        {
          img: ic2,
          p1: 'Scale your Operation',
          p2: 'Blood Sample',
          content:
            'Make informed and timely decision with real time data and alarm triggers using the Mote Digital Data Logger',
        },
        {
          img: ic3,
          p1: 'Eliminate Watse',
          p2: 'Vaccine vials',
          content:
            'Ensure appropriate storage of specimens, vaccines, and other temperature-sensitive products in storage and transit',
        },
      ],
      clientImg: [
        {img: cl1},
        {img: cl2},
        {img: cl3},
        {img: cl4},
        {img: cl5},
        {img: cl6},
        {img: cl7},
        {img: cl8},
        {img: cl9},
        {img: cl10},
        {img: cl11},
        {img: cl12},
        {img: cl13},
      ],
    };
  },
  methods: {
    validate() {
      const isVal = this.$refs.form.validate();
      const data = {
        fullname: this.name,
        phone: this.phone.number,
        email: this.email,
        company: this.select,
        date: this.date,
        time: this.displayTime,
        industry: this.industry,
      };
      if (this.comment === '') delete data.message;
      if (!isVal) return;
      else this.loading = true;
      axios
        .post('https://stage-gricd.herokuapp.com/v2/admin/demo', data)
        .then(() => {
          //   this.info = response.data.message;
          // this.$toast.success(response.data.message);
          this.$router.push('/demo-request-success');
          this.reset();
        })
        .catch((error) => {
          this.errored = true;
          this.loading = false;
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onInput(formattedNumber, {number, valid}) {
      if (number && number.input !== '') {
        if (valid) {
          this.phone.number = number.international;
          this.phoneError = [];
          this.phone.number = number.international;
        } else {
          this.phoneError = ['Invalid number'];
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    inputHandler(eventData) {
      console.log(eventData.displayTime);
      this.displayTime = eventData.displayTime;
    },
    goMote: () => {
      location.href = 'https://buy.gricd.com/product-category/products/';
    },
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      // console.log(val);
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.top-text {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
p {
  font-size: 0.9rem;
}
.button {
  font-size: 12px;
  background: #28a84a;
}
.v-text-field {
  border-radius: 3px;
}
// .v-text-field--outlined ::v-deep fieldset {
//   border-color: rgba(99, 99, 99, 0.3);
// }
span {
  color: #28a84a;
  font-weight: 500;
}
.reply {
  font-weight: 0.9rem;
}
// p.v-toast__text {
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
//     Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     font-weight: 800;
//     font-size: 53px !important;
// }
.v-toast ::v-deep .v-toast__item {
  font-family: 'Roboto-Regular' !important ;
  color: white !important;
}
.name {
  column-gap: 2rem;
  display: flex;
  @media (max-width: 599px) {
    // column-gap: 1rem;
    display: block;
  }
}

.timepicker ::v-deep input[type='text'] {
  height: 55.8px;
  width: 100%;
  border-radius: 3px;
  background-color: transparent;
  border: solid 1px #9e9e9e;
}

.time {
  width: 47%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 2rem;
  }
}
::v-deep span[data-v-88e0ea68] {
  width: 100% !important;
  font-weight: 300 !important;
  font-size: 1rem;
}
::v-deeep .vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #28a84a;
  color: #fff;
}
::-webkit-scrollbar {
  // width: 2px;
  height: 0%;
}

.container_ {
  position: relative;
}
.apparatus-card {
  max-width: 20rem;
  // border: solid;
  height: 15rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.p1 {
  font-size: 1rem;
  font-weight: 500;
}
.p2 {
  font-size: 1rem;
  font-weight: 500;
  color: #28a84a;
}
::v-deep span.vue__time-picker.time-picker.timepicker {
  width: 100% !important;
}
.custom-header {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 769px) {
    width: 80%;
  }
}
.d-shape {
  //   clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  //   clip-path: polygon(20% 5%, 80% 0%, 80% 100%, 20% 95%);
  height: auto;
  //   background: linear-gradient(
  //     180deg,
  //     rgba(196, 196, 196, 0.83) 3.28%,
  //     #f2f5f7 95.82%
  //   );
  //   border: 2px solid rgba(255, 255, 255, 0.6);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23rem;
  float: right;

  img {
    height: 16rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.right-col {
  display: flex;
  justify-content: center;
}
.ankara {
  // background: url('../../assets/images/Ankara 1.png');
  background: rgba(196, 196, 196, 0.21);
  position: relative;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 0;
  cursor: pointer;
}
</style>
